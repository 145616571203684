import React from 'react'
import { useInView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

const IntroText = ({ content, hideBorder, addClass }) => {
  const [ref, inView] = useInView()
  return (
    <div
      className={`${styles.introText}
      ${styles[addClass]}
        ${hideBorder ? styles.hideBorder : ''}
        ${inView ? 'in-view' : ''}
        fade-in-up
    `}
      ref={ref}
    >
      <div className={styles.inner}>{content}</div>
    </div>
  )
}

export default IntroText

import React from 'react'
import { useInView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

import LineLink from 'components/LineLink'

const Breadcrumb = ({ items }) => {
  const [ref, inView] = useInView()

  return (
    <div
      className={`${styles.breadcrumb} pad-sides fade-in 
      ${inView ? 'in-view' : ''}
      `}
      ref={ref}
    >
      <div className='inner'>
        <p>
          {items.map((item, index) => {
            return <Item key={index} index={index} params={item} />
          })}
        </p>
      </div>
    </div>
  )
}

const Item = (props) => {
  const { text, link } = props.params
  const { index } = props
  if (index === 0) {
    return (
      <span>
        <LineLink to={link} text={text} />
      </span>
    )
  } else if (link) {
    return (
      <span>
        <span className={styles.arrowRight}></span>
        <LineLink to={link} text={text} />
      </span>
    )
  } else {
    // is active
    return (
      <span>
        <span className={styles.arrowRight}></span>
        <span
          className={styles.active}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </span>
    )
  }
}

export default Breadcrumb

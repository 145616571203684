import React from 'react'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Inview from 'components/Inview'

const BgLink = ({
  to,
  text,
  external,
  isButton,
  isFilledGreen,
  isWhite,
  isCentered,
  isFadeInUp,
  isLineLink,
}) => {
  return (
    <Inview>
      <div className='fade-in-up'>
        {external && (
          <a
            href={to}
            target='_blank'
            rel='noopener noreferrer'
            className={`
            bg-link styled
            ${isButton ? ' button' : ''}
            ${isCentered ? ' is-centered' : ''}
            ${isFilledGreen ? ' filled-green' : ''}
            ${isWhite ? ' white' : ''}
            ${isFadeInUp ? ' fade-in-up' : ''}
            ${isLineLink ? ' line-link' : ''}
            `}
          >
            <span className='text'>{text}</span>
            <span className='bg'>
              <span className='inner-text'>{text}</span>
            </span>
          </a>
        )}
        {!external && (
          <LocalizedLink
            to={to}
            className={`
            bg-link styled
            ${isButton ? ' button' : ''}
            ${isCentered ? ' is-centered' : ''}
            ${isFilledGreen ? ' filled-green' : ''}
            ${isWhite ? ' white' : ''}
            ${isFadeInUp ? ' fade-in-up' : ''}
            ${isLineLink ? ' line-link' : ''}
            _fade-in _stagger-800
            `}
          >
            <span className='text'>{text}</span>
            <span className='bg'>
              <span className='inner-text'>{text}</span>
            </span>
          </LocalizedLink>
        )}
      </div>
    </Inview>
  )
}

export default BgLink

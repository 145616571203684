import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './portfolio.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import ImageWrap from 'components/ImageWrap'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`].nodes[0]
  const introText = renderRichText(page.introText)

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  // let seoDescription = {
  //   en:
  //     'Applying a “Global Supply, Chinese Consumption” investment thesis, Hosen executes in cross-border transactions to build leading food businesses.',
  //   zh:
  //     '秉承“全球供应，中国消费”的投资理念，厚生自2010年起布局全球食品供应链上下游各细分领域，包括消费升级、产业升级及领先生物技术。',
  // }
  // seoDescription = seoDescription[locale]

  return (
    <Layout>
      <div className={styles.portfolio}>
        <Seo
          pageTitle={page.title}
          // description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} hideBorder={true} />
            <div className={styles.main}>
              {page.portfolioCompanies.map((item, i) => (
                <PortfolioCompany content={item} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

const PortfolioCompany = ({ content }) => {
  const { logo, title, description, link } = content
  const { locale } = useLocalization()
  const descriptionText = renderRichText(description)
  return (
    <Inview>
      <div className={`${styles.portfolioCompany} fade-in-up`}>
        <div className={styles.logo}>
          <ImageWrap image={logo} />
        </div>
        <div className={styles.text}>
          <h3>{title}</h3>
          <div>{descriptionText}</div>
          <BgLink
            to={link}
            external={true}
            text={`${tr('VISIT_WEBSITE', locale)} »`}
          />
        </div>
      </div>
    </Inview>
  )
}

export const pageQuery = graphql`
  query {
    page_en: allContentfulPortfolioPage(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        portfolioCompanies {
          logo {
            ...GatsbyImage
          }
          title
          description {
            raw
          }
          link
        }
      }
    }
    page_zh: allContentfulPortfolioPage(
      filter: { node_locale: { eq: "zh-CN" } }
    ) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        portfolioCompanies {
          logo {
            ...GatsbyImage
          }
          title
          description {
            raw
          }
          link
        }
      }
    }
  }
`
